import React from "react";
import ProductPage from "../../components/Products/ProductPage";

import brands from "../../assets/img/products/electrical/brands.png"

import p1 from "assets/img/products/electrical/1.jpg";
import p2 from "assets/img/products/electrical/2.jpg";
import p3 from "assets/img/products/electrical/3.jpg";
import p4 from "assets/img/products/electrical/4.jpg";
import p5 from "assets/img/products/electrical/5.png";
import p6 from "assets/img/products/electrical/6.jpg";
import p7 from "assets/img/products/electrical/7.png";
import p8 from "assets/img/products/electrical/8.jpg";
import p9 from "assets/img/products/electrical/9.jpg";
import p10 from "assets/img/products/electrical/10.jpg";
import p11 from "assets/img/products/electrical/11.png";
import p12 from "assets/img/products/electrical/12.jpg";
import p13 from "assets/img/products/electrical/13.jpg";

import s1 from "assets/img/products/electrical/slider/1.jpg";
import s2 from "assets/img/products/electrical/slider/2.jpg";
import s3 from "assets/img/products/electrical/slider/3.jpg";

export default () => (
  <ProductPage prod="Electrical" url="electrical" brandsImg={brands} products={products} sliders={sliders} />
);

const sliders = [
    { img: s1},
    { img: s2},
    { img: s3}
]

const products = [
    { name: 'Light Fittings',      img: p1 },
    { name: 'Light Management Systems ',          img: p2 },
    { name: 'Lasers & Sensors',            img: p3 },
    { name: 'Lighting Control',           img: p4 },
    { name: 'LED Light Modules',           img: p5 },
    { name: 'Bulbs',             img: p6 },
    { name: 'Cables & Wires',img: p7 },
    { name: 'Socket & Adapters',   img: p8 },
    { name: 'Cable Glands',           img: p9 },
    { name: 'Relays',        img: p10 },
    { name: 'Capacitors',            img: p11 },
    { name: 'Fuse',           img: p12 },
    { name: 'Distribution Box',       img: p13 }
]